import {Details, Reservation, Status} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {zonedTimeToUtc} from '@wix/table-reservations-lib/timezone'
import addHours from 'date-fns/addHours'

import {getNearestQuarterTime} from '../../utils/date'
import {setDataToMemoryCache, getDataFromMemoryCache} from '../../utils/dataCapsule'
import {goToReservationConfirmation, goToReservationDetails} from '../../utils/navigation'

import {EDITOR_MOCK_RESERVATION_LOCATION_ID} from './getReservationLocationsMock'

export const RESERVATION_ID_MOCK = 'eb6fad5d-2f21-42d2-a330-84c4bb7df34d'

const CACHE_KEY = 'tr-reservation-mock'

export const handleReservationDataMock = (flowAPI: ControllerFlowAPI) => {
  return async ({details, timezone}: {details: Details; timezone?: string | null}) => {
    setDataToMemoryCache(flowAPI, CACHE_KEY, {
      id: RESERVATION_ID_MOCK,
      status: Status.HELD,
      details: {
        reservationLocationId: details.reservationLocationId,
        startDate: zonedTimeToUtc(details.startDate!, timezone),
        endDate: addHours(zonedTimeToUtc(details.startDate!, timezone), 2),
        partySize: details.partySize,
      },
    })

    await goToReservationDetails({
      flowAPI,
      isTemplateView: true,
      reservationId: RESERVATION_ID_MOCK,
    })
  }
}

export const reserveReservationMock = (flowAPI: ControllerFlowAPI) => {
  return async () => {
    const reservationFromCache = getReservationMock(flowAPI, Status.RESERVED)

    setDataToMemoryCache(flowAPI, CACHE_KEY, reservationFromCache)

    await goToReservationConfirmation(flowAPI, RESERVATION_ID_MOCK, true)
  }
}

export const getReservationMock = (
  flowAPI: ControllerFlowAPI,
  status?: Status,
  fallbackReservationLocationId?: string,
): Reservation => {
  const reservationFromCache = getDataFromMemoryCache<Reservation>(flowAPI, CACHE_KEY)
  if (reservationFromCache) {
    return {
      ...reservationFromCache,
      status: status ?? reservationFromCache.status,
      details: {
        ...reservationFromCache.details,
        startDate: new Date(reservationFromCache.details!.startDate!),
        endDate: new Date(reservationFromCache.details!.endDate!),
      },
    }
  } else {
    const date = getNearestQuarterTime(new Date())

    return {
      id: RESERVATION_ID_MOCK,
      status: status ?? Status.HELD,
      details: {
        reservationLocationId: fallbackReservationLocationId ?? EDITOR_MOCK_RESERVATION_LOCATION_ID,
        startDate: date,
        endDate: date,
        partySize: 2,
      },
    }
  }
}
