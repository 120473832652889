import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservation} from '@wix/ambassador-table-reservations-v1-reservation/types'

import {reservationsService} from '../../../services/reservationsService'
import {ApiError, isWixHttpError} from '../../../utils/errors'
import {RequestStatus} from '../../../utils/wrapRequest'
import {goToReservationConfirmation} from '../../../utils/navigation'

export enum ReserveReservationApplicationErrorCode {
  HELD_EXPIRED = 'HELD_EXPIRED',
}

export interface ReserveReservationApiError
  extends ApiError<ReserveReservationApplicationErrorCode> {}

export const reserveReservation = (flowAPI: ControllerFlowAPI) => {
  return async (
    reservation: Reservation,
    reservee: {
      firstName: string
      lastName?: string
      phone: string
      email?: string
      marketingConsent?: boolean
      customFields?: {[key: string]: string}
    },
  ) => {
    const setProps = flowAPI.controllerConfig.setProps

    try {
      setProps({submitReservationStatus: RequestStatus.LOADING})
      const finishedReservation = await reservationsService.reserveReservation(
        flowAPI,
        reservation.id ?? '',
        reservee,
        reservation.revision ?? '',
      )
      await goToReservationConfirmation(flowAPI, finishedReservation?.id)
    } catch (err: any) {
      const apiError: ReserveReservationApiError = {}

      if (isWixHttpError(err)) {
        apiError.applicationCode = err?.response?.data?.details?.applicationError
          ?.code as ReserveReservationApplicationErrorCode
        apiError.message = err?.response?.data?.message
      } else {
        apiError.message = err?.message
      }

      setProps({
        submitReservationStatus: RequestStatus.FAILED,
        apiRequestErrorDetails: apiError,
      })
    }
  }
}
