import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservee, Details} from '@wix/ambassador-table-reservations-v1-reservation/types'

import {reservationsService} from '../../../services/reservationsService'
import {RequestStatus} from '../../../utils/wrapRequest'
import {goToReservationConfirmation} from '../../../utils/navigation'

export const createReservation = (flowAPI: ControllerFlowAPI) => {
  return async (reservee: Reservee, details: Details) => {
    const setProps = flowAPI.controllerConfig.setProps

    try {
      setProps({submitReservationStatus: RequestStatus.LOADING})
      const finishedReservation = await reservationsService.createReservation(flowAPI, {
        details,
        reservee,
      })
      await goToReservationConfirmation(flowAPI, finishedReservation?.id)
    } catch (err: any) {
      setProps({
        submitReservationStatus: RequestStatus.FAILED,
        apiRequestErrorDetails: err,
      })
    }
  }
}
